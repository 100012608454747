<template>
  <div>
    <main class="site-content container">
      <div>
        <ul role="tablist" id="iid02" class="zones-nav nav">
          <li class="nav-item">
            <span
              id="bedrijven-tab"
              data-toggle="tab"
              href="#bedrijven"
              role="tab"
              aria-controls="bedrijven"
              aria-selected="false"
              class="nav-link search-cursor-tab active text-uppercase"
              >Bedrijven</span
            >
          </li>
          <li class="nav-item">
            <span
              id="news-tab"
              data-toggle="tab"
              href="#nieuws"
              role="tab"
              aria-controls="news"
              aria-selected="false"
              class="nav-link search-cursor-tab text-uppercase"
              >Nieuws</span
            >
          </li>
          <li class="nav-item">
            <span
              id="events-tab"
              data-toggle="tab"
              href="#events"
              role="tab"
              aria-controls="contact"
              aria-selected="false"
              class="nav-link search-cursor-tab text-uppercase"
              >Events</span
            >
          </li>
          <li class="nav-item">
            <span
              id="jobs-tab"
              data-toggle="tab"
              href="#jobs"
              role="tab"
              aria-controls="jobs"
              aria-selected="true"
              class="nav-link search-cursor-tab text-uppercase"
              >Jobs</span
            >
          </li>
        </ul>
        <div id="isj6n" class="tab-content">
          <div
            id="bedrijven"
            role="tabpanel"
            aria-labelledby="bedrijven"
            class="tab-pane active show"
          >
            <div
              id="i61qo"
              class="search-main-container search-main-container__bedrijven"
            >
              <autocomplete
                :get-result-value="getResultValue"
                :search="searchBedrijven"
                :placeholder="`Zoek &amp; vind in ${zoneName} ...`"
                @submit="handleSubmit"
              >
                <template #result="{ result, props }">
                  <li v-bind="props" class="autocomplete-result">
                    <div
                      class="
                        autocomplete-result-item autocomplete-result-item-big
                      "
                    >
                      <img :src="renderImageFromBlogPost(result.content.$t) || defaultImage" @error="detectImageError" />
                      <h4>{{ result.title.$t }}</h4>
                    </div>
                  </li>
                </template>
              </autocomplete>
              <button
                id="iv4rlg"
                class="
                  button
                  search-btn
                  text-uppercase
                  btn btn-primary
                  text-white
                  border-0
                "
                @click="handleSubmit('')"
              >
                Zoeken
              </button>
            </div>
          </div>
          <div
            id="nieuws"
            role="tabpanel"
            aria-labelledby="news-tab"
            class="tab-pane fade"
          >
            <div
              id="i25zpf"
              class="search-main-container search-main-container__nieuws"
            >
              <autocomplete
                :get-result-value="getResultValuePost"
                :search="searchNews"
                :placeholder="`Zoek &amp; vind nieuws in ${zoneName} ...`"
                @submit="handleSubmitNews"
              >
                <template #result="{ result, props }">
                  <li v-bind="props" class="autocomplete-result">
                    <div
                      class="
                        autocomplete-result-item autocomplete-result-item-big
                      "
                    >
                      <img :src="renderImageFromBlogPost(result.content.$t) || defaultImage" @error="detectImageError" />
                      <h4>{{ result.title.$t }}</h4>
                    </div>
                    <!-- <div class="wiki-snippet" v-html="result.snippet" /> -->
                  </li>
                </template>
              </autocomplete>
              <button
                id="ioj1o1"
                class="
                  button
                  search-btn
                  text-uppercase
                  btn btn-primary
                  text-white
                  border-0
                "
                @click="handleSubmitNews"
              >
                Zoeken
              </button>
            </div>
          </div>
          <div
            id="events"
            role="tabpanel"
            aria-labelledby="events-tab"
            class="tab-pane fade"
          >
            <div class="search-main-container search-main-container__events">
              <autocomplete
                :get-result-value="getResultValuePost"
                :search="searchEvents"
                :placeholder="`Zoek &amp; vind events in ${zoneName} ...`"
                @submit="handleSubmitEvents"
              >
                <template #result="{ result, props }">
                  <li v-bind="props" class="autocomplete-result">
                    <div
                      class="
                        autocomplete-result-item autocomplete-result-item-big
                      "
                    >
                      <img :src="renderImageFromBlogPost(result.content.$t) || defaultImage" @error="detectImageError" />
                      <h4>{{ result.title.$t }}</h4>
                    </div>
                  </li>
                </template>
              </autocomplete>
              <button
                id="iveyni"
                class="
                  button
                  search-btn
                  text-uppercase
                  btn btn-primary
                  text-white
                  border-0
                "
                @click="handleSubmitEvents"
              >
                Zoeken
              </button>
            </div>
          </div>
          <div
            id="jobs"
            role="tabpanel"
            aria-labelledby="jobs-tab"
            class="tab-pane fade"
          >
            <div
              id="izcf7i"
              class="search-main-container search-main-container__jobs"
            >
              <autocomplete
                :get-result-value="getResultValuePost"
                :search="searchJobs"
                :placeholder="`Zoek &amp; vind jobs in ${zoneName} ...`"
                @submit="handleSubmitJobs"
              >
                <template #result="{ result, props }">
                  <li v-bind="props" class="autocomplete-result">
                    <div
                      class="
                        autocomplete-result-item autocomplete-result-item-big
                      "
                    >
                      <img :src="renderImageFromBlogPost(result.content.$t) || defaultImage" @error="detectImageError" />
                      <h4>{{ result.title.$t }}</h4>
                    </div>
                  </li>
                </template> </autocomplete
              ><button
                id="i7pbd4"
                class="
                  button
                  search-btn
                  text-uppercase
                  btn btn-primary
                  text-white
                  border-0
                "
                @click="handleSubmitJobs"
              >
                Zoeken
              </button>
            </div>
          </div>
        </div>
      </div>

      <section>
        <div
          v-if="
            (type === 'search' &&
              searchLoaded &&
              finalSearchResult.length === 0) ||
            !finalSearchResult
          "
          class="zones-placeholder-container"
        >
          <h3>Geen resultaat gevonden.</h3>
        </div>
        <div v-if="type === 'search-post'" class="zones-placeholder-container">
          <div v-if="finalSearchResult.length === 0">
            <h3>Geen resultaat gevonden.</h3>
          </div>

          <div
            v-if="finalSearchResult.length > 0"
            class="zones-placeholder-container"
          >
            <div>
              <h2>Resultaten: {{ finalSearchResult.length }}</h2>
              <table class="table table-hover table-striped">
                <thead>
                  <th><strong>Name</strong></th>
                </thead>
                <tr v-for="resultItem in finalSearchResult">
                  <td class="zones-result-container">
                    <div class="zones-search-result__image">
                      <a :href="readPost(resultItem)">
                        <img
                          :src="
                            renderImageFromBlogPost(resultItem.content.$t) ||
                            defaultImage.favicon
                          "
                      /></a>
                    </div>
                    <div class="zones-search-result__description">
                      <h4 class="mb-3">
                        <a :href="readPost(resultItem)">{{
                          resultItem.title.$t
                        }}</a>
                      </h4>
                      <p
                        v-if="currentTarget != 'business'"
                        class="mb-2 zones-search-result__description-p"
                        v-html="prune(stripHtml(resultItem.content.$t))"
                      ></p>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>

        <div v-if="searchState === 'all'">
          <div class="prices mb-5">
            <div
              class="
                flex
                border-b-1 border-gray-light
                headline
                f-align-end
                b--spacer
                f-justify-between
                flex-row
              "
            >
              <h2 class="mb-0 secondary-header">ZONE Leden</h2>
            </div>

            <div class="grid zones-staff-pick">
              <div
                v-for="zoneItem in zoneMembers"
                :key="zoneItem.id"
                class="zones-staff-pick__item"
              >
                <a :href="readPost(zoneItem)" class="link-background"
                  ><img :src="zoneItem.image" />
                </a>
                <div class="zones-staff-pick__name">
                  <a :href="readPost(zoneItem)">
                    <h3 class="place-names">{{ zoneItem.title.$t }}</h3>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="mb-5 g-maps">
            <iframe :src="this.map" width="100%" height="480"></iframe>
          </div>

          <div class="mb-4">
            <div
              class="
                flex
                border-b-1 border-gray-light
                headline
                f-align-end
                b--spacer
                f-justify-between
                flex-row
              "
            >
              <h2 class="mb-0 secondary-header">ZONE Vlaanderen</h2>
            </div>

            <div class="grid grid-explore">
              <div
                class="grid-explore-item"
                v-for="(zonesItem, index) in pageOfItems"
                :key="index"
              >
                <a :href="readPost(zonesItem)" class="link-background">
                  <img :src="zonesItem.image"
                /></a>
                <div
                  class="p-4"
                  style="
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                  "
                >
                  <h3 class="place-names">{{ zonesItem.title.$t }}</h3>
                  <a :href="readPost(zonesItem)" class="btn__custom w-100"
                    >ONTDEK MEER</a
                  >
                </div>
              </div>
            </div>
          </div>

          <Pagination
            :items="companyLists"
            @changePage="onChangePage"
            :pageSize="16"
          />

          <hr />
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import ReadMore from "@/components/readmore.vue";
import Pagination from "@/components/pagination.vue";
import { getImageFromBlogPost } from "@/helpers/index.js";
export default {
  components: {
    ReadMore,
    Pagination,
  },
  data() {
    return {
      currentTarget: "",
      blogUrl: "",
      zoneName: "",
      DOMAIN_URL: "https://www.zone-houthalen-helchteren.be/",
      BLOG_SOURCE: "https://zone-houthalen-helchteren.blogspot.com/",
      loaded: false,
      searchLoaded: false,
      type: "home",
      searchState: 'all',
      map: "",
      searchField: {
        business: "",
        news: "",
        jobs: "",
        events: "",
      },
      searchResult: {
        business: [],
        news: [],
        jobs: [],
        events: [],
      },
      finalSearchResult: [],
      zones: [],
      news: [],
      jobs: [],
      events: [],
      // zonesPick: [],
      zoneMembers: [],
      zonesRandom: [],
      sectors: {},
      companyLists: [],
      pageOfItems: [],
      defaultImage: {
        cover:
          "https://1.bp.blogspot.com/-8MFatybaSXo/YBL1-G3qybI/AAAAAAAACQw/AD2EHCGPzZk-XsDZ9YmjdHtVlvvd9KbWQCLcBGAsYHQ/s1200/bg-mechelen-noord.jpg",
        favicon:
          "https://uptodatewebdesign.s3.eu-west-3.amazonaws.com/uploads/photos-1618802110694.png",
      },
    };
  },
  created() {
    this.blogUrl = this.zoneAttributes.url;
    this.map = this.zoneAttributes.map;
    this.zoneName = this.zoneAttributes.name;
    this.searchState = this.zoneAttributes.searchState;
    this.companyLabel = this.zoneAttributes.companyLabel || 'bedrijven';
    this.DOMAIN_URL = this.zoneAttributes.url + "/";
    this.getAllBusinesses();
  },
  methods: {
    removeNbsp: (str) => {
      return str.replace(/&nbsp;/gi, "");
    },
    stripHtml: (str, elemToUse = "") => {
      if (!str) {
        return "";
      }
      return utils.removeNbsp(str.replace(/<(?:.|\n)*?>/gm, elemToUse));
    },
    prune: (str, length = 250) => {
      return str.length > length ? str.substring(0, length - 3) + "..." : str;
    },
    jsonp(url) {
      return new Promise((resolve, reject) => {
        var callbackName =
          "jsonp_callback_" + Math.round(100000 * Math.random());
        window[callbackName] = function (data) {
          delete window[callbackName];
          document.body.removeChild(script);
          //callback(data);
          resolve(data);
        };

        var script = document.createElement("script");
        script.src =
          url +
          (url.indexOf("?") >= 0 ? "&" : "?") +
          "callback=" +
          callbackName;
        document.body.appendChild(script);
      });
    },
    async getData(label) {
      // console.log("label:", label);
      let startIndex = 1;
      let selected = [];
      let isCompleted = false;

      const getBlogPost = async () => {
        let url = `${this.blogUrl}/feeds/posts/default?start-index=${startIndex}&max-results=150&alt=json`;
        if (label) {
          url = `${this.blogUrl}/feeds/posts/default/-/${label}?start-index=${startIndex}&max-results=150&alt=json`;
        }
        // let result = await fetch(url);
        let result = await this.jsonp(url);
        if (result && result.feed.entry) {
          selected = [...selected, ...result.feed.entry];
          if (result.feed.entry.length >= 150) {
            startIndex += result.feed.entry.length;
            getBlogPost();
          } else {
            isCompleted = true;
            return selected;
          }
        } else {
          isCompleted = true;
          return selected;
        }
      };

      let dataResult = await getBlogPost();
      return dataResult;
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },

    getResultValuePost(result) {
      return result.title.$t;
    },
    getResultValue(result) {
      return result.name;
    },
    renderSearchResult(type, payload) {
      this.type = "search";
      if (type === "business" && payload) {
        this.finalSearchResult = payload;
      } else if (type === "post" && payload) {
        this.finalSearchResult = payload;
      } else if (!payload) {
        this.finalSearchResult = [];
      }
      this.searchLoaded = true;
    },
    filterBusinesses(sector, toScrollUp) {
      if (toScrollUp) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
      this.renderSearchResult("business", this.sectors[sector]);
    },
    handleSubmit(result) {
      this.handlePost(result, "business");
    },
    renderImageFromBlogPost(content) {
      return getImageFromBlogPost(content);
    },
    handlePost(result, objTarget) {
      this.currentTarget = objTarget;
      this.renderSearchResult("post", this.searchResult[objTarget]);
      this.type = "search-post";
      if (result) {
        let link = result.link.filter((l) => l.rel == "alternate")[0].href;
        // link = link.split(this.BLOG_SOURCE);
        window.location.href = link;
      }
    },
    handleSubmitNews(result) {
      this.handlePost(result, "news");
    },
    handleSubmitEvents(result) {
      this.handlePost(result, "events");
    },
    handleSubmitJobs(result) {
      this.handlePost(result, "jobs");
    },
    readPost(result) {
      let link = result.link.filter((l) => l.rel == "alternate")[0].href;
      return link;
    },
    search(input) {
      this.searchField.business = input;
      this.searchResult.business =
        this.zones.filter((zonesItem) => {
          return zonesItem.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }) || [];
      return this.searchResult.business;
    },
    async searchBedrijven(input) {
      if (this.zones.length == 0) {
        this.zones = await this.apiGetNews("zones", this.companyLabel);
      }
      this.searchResult.business =
        this.zones.filter((post) => {
          return post.title.$t.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }) || [];
      return this.searchResult.business;
    },
    async searchNews(input) {
      if (this.news.length == 0) {
        this.news = await this.apiGetNews("news", "nieuws");
      }
      this.searchResult.news =
        this.news.filter((post) => {
          return post.title.$t.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }) || [];
      return this.searchResult.news;
    },
    async apiGetNews(type, label) {
      try {
        return new Promise(async (resolve, reject) => {
          let result = await this.getData(label);
          this[type] = result;
          resolve(result);
        });
      } catch (err) {
        console.log(err);
      }
    },
    async searchJobs(input) {
      this.searchField.jobs = input;
      if (this.jobs.length == 0) {
        this.jobs = await this.apiGetNews("jobs", "jobs");
      }

      this.searchResult.jobs =
        this.jobs.filter((post) => {
          return post.title.$t.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }) || [];
      return this.searchResult.jobs;
    },
    async searchEvents(input) {
      this.searchField.events = input;
      if (this.events.length === 0) {
        this.events = await this.apiGetNews("events", "events");
      }

      this.searchResult.events =
        this.events.filter((post) => {
          return post.title.$t.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }) || [];
      return this.searchResult.events;
    },
    shuffleArray(array) {
      for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
      return array;
    },
    getListByName() {},
    async getAllBusinesses() {
      try {
        if (this.zones.length == 0) {
          await this.apiGetNews("zones", this.companyLabel);

          this.zones = this.zones.map((zoneItem) => {
            zoneItem.image =
              this.renderImageFromBlogPost(zoneItem.content.$t) ||
              this.defaultImage.cover;
            return zoneItem;
          });
          this.zonesRandom = this.shuffleArray(this.zones);
          this.zoneMembers = this.zones.slice(0, 8);
          this.companyLists = this.zones;
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style>
:root {
  --theme: #15303d;
  --theme-hover: #9e1f31;
}

/* .site-content {
  font-family: Circular, Helvetica, Arial, sans-serif;
} */

.site-content img {
  max-width: 100%;
}

.zones-staff-pick {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.zones-staff-pick div {
  max-width: 100%;
}

.zones-staff-pick img {
  height: 200px;
  width: 100%;
  object-fit: contain;
  position: relative;
  z-index: 9;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.5);
}

.zones-staff-pick__item {
  width: 100%;
  position: relative;
}

.zones-staff-pick__item:hover,
.grid-explore-item img:hover,
.zones-sector img:hover {
  opacity: 0.9;
}

.zones-staff-pick__sector {
  position: absolute;
  top: 10px;
  left: 10px;
  color: white;
  font-weight: bold;
}

.zones-staff-pick__name {
  margin-top: 20px;
}

/*---title---*/

.zone-title {
  font-size: 46px;
  font-weight: 100;
  color: #484848;
}

.zone-title span {
  font-weight: 800;
  color: var(--theme-hover);
}

/*---gallery-prices---*/

.prices {
  margin-top: 50px;
}

.img {
  display: inline-block;
  margin: 30px 5px 0 5px;
}

.img:last-child {
  margin-right: 0;
}

.img img {
  width: 301px;
  height: 206px;
  object-fit: cover;
}

.price-stamp {
  color: #fff;
  font-weight: 900;
  font-size: 16px;
  text-shadow: 0 0 6px #615e61;
}

.description {
  font-size: 14px;
  color: #484848;
}

/*---line---*/

hr {
  border: 0;
  height: 1px;
  background: #dce0e0;
  margin-bottom: 55px;
}

/*---gallery-places---*/

.gallery-places {
  margin-bottom: 20px;
}

.places {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: block;
}

/*---gallery-guidebooks---*/

.guidebooks {
  display: block;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.guidebooks h3 {
  font-weight: 100;
  font-size: 25px;
  color: #484848;
}

.img-box {
  float: left;
  /* width: 305px; */
  height: 205px;
  /* margin: 40px 5px 0 5px; */
}

.img-box img {
  object-fit: cover;
}

.img-box:last-child {
  margin-right: 0;
}

.img-box h4 {
  font-size: 14px;
  color: #484848;
  margin: 0;
}

.img-box h4 span {
  margin-left: -1px;
}

.img-box p {
  font-size: 14px;
  color: #484848;
}

.img-big {
  float: left;
  margin-top: 3px;
  width: 200px;
  height: 163px;
}

.img-small {
  float: right;
  width: 100px;
  height: 80px;
  margin-top: 3px;
}

.gallery-places h3,
.zone-subheader {
  font-weight: 100;
  font-size: 25px;
  color: #484848;
  margin: 0 0 35px 0;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.search-cursor-tab {
  cursor: pointer;
  background-color: var(--theme);
  color: #dedbdb;
  border-radius: 0;
  font-size: 18px;
  padding: 12px 20px;
  font-family: "Lato", san-serif;
  margin-right: 10px;
  font-family: Lato, sans-serif;
}

.search-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-input-field,
.autocomplete-input {
  height: 60px;
  margin: 0 20px 0 0;
  font-size: 22px !important;
  border-radius: 0 !important;
  border: 1px solid #dedede;
  background-color: #fcfcfc !important;
}

.active.search-cursor-tab {
  background-color: var(--theme-hover) !important;
  border-color: var(--theme-hover);
  color: white !important;
}

.search-main-container div {
  width: 100%;
  margin-right: 20px;
  z-index: 10;
}

.autocomplete-result-item {
  display: flex;
}

.autocomplete-result-item img {
  width: 30px;
  height: 30px;
  margin-right: 20px;
  object-fit: contain;
  border: 1px solid #ddd;
  padding: 5px;
}

.autocomplete-result-item-big img {
  width: 60px;
  min-width: 60px;
  height: 60px;
  object-fit: cover;
}

.autocomplete-result-item-big h4 {
  display: flex;
  max-width: 80%;
}

.zones-placeholder {
  margin-bottom: 20px;
}

.zones-placeholder-container {
  border: 1px solid #eee;
  padding: 20px;
  background: white;
  margin-top: 20px;
}

.btn,
.utd-btn {
  border-radius: 0;
}

.zones-result-container {
  display: flex;
  padding-top: 20px !important;
  border-bottom: 1px solid #eee;
}

.zones-search-result__image {
  margin-right: 30px;
  /* padding: 5px; */
}

.zones-search-result__image img {
  width: 100px;
  height: 70px;
  /* margin-right: 20px; */
  object-fit: contain;
  border: 1px solid #ddd;
  padding: 5px;
}

.autocomplete-result {
  cursor: pointer;
  padding: 10px;
  background: white;
}

.zones-search-result__description {
  width: 100%;
}

.zones-search-result__description p {
  color: #777;
}

.zones-search-result__description span {
  /* color: #aaa; */
  margin-bottom: 10px;
}

.text-link {
  cursor: pointer;
  font-family: "Lato", san-serif;
}

@media screen and (max-width: 768px) {
  .search-btn {
    display: none;
  }

  .search-main-container {
    flex-direction: column;
  }

  .zones-nav .nav-item {
    margin-bottom: 10px;
  }

  .zones-search-result__description {
    margin-top: 20px;
  }

  .search-main-container div {
    margin: 0;
  }

  .search-cursor-tab {
    font-size: 14px;
    padding: 10px 15px;
  }

  .zone-title {
    text-align: center;
  }

  .search-input-field,
  .autocomplete-input {
    font-size: 16px !important;
  }

  .autocomplete-result-item-big img {
    width: 50px;
    height: 50px;
    cursor: pointer;
  }
}

.zones-sector-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.zones-sector {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 5px;
}

.zones-sector-container h4 {
  color: var(--theme);
  font-family: "Manuale", san-serif;
}

.zones-sector img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.zones-sector__big {
  grid-area: 1 / 1 / 3 / 2;
  height: 240px;
  width: 100%;
}

.zones-sector__small-top {
  grid-area: 1 / 2 / 2 / 3;
  height: 118px;
  width: 100%;
}

.zones-sector__small-bottom {
  grid-area: 2 / 2 / 3 / 3;
  height: 118px;
  width: 100%;
}

.grid-explore {
  display: grid;
  grid-gap: 20px;
}

.grid-explore-item {
  position: relative;
  border: 1px solid #ccc;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.grid-explore-item img {
  width: 100%;
  height: 200px;
  object-fit: contain;
  position: relative;
  z-index: 10;
  padding: 10px;
}

@media only screen and (max-width: 768px) {
  .zones-sector-container,
  .zones-staff-pick {
    display: flex;
    flex-wrap: wrap;
  }

  .zones-result-container {
    display: unset;
  }

  .zones-staff-pick a,
  .zones-staff-pick div {
    width: 100%;
  }

  .search-btn {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 768px) {
  .grid-explore {
    grid-template-columns: repeat(9, 1fr);
  }

  .grid-explore-item {
    grid-column: span 3;
    grid-template-rows: 1fr;
  }
}

@media only screen and (min-width: 992px) {
  .grid-explore {
    grid-template-columns: repeat(12, 1fr);
  }

  .grid-explore-item {
    grid-column: span 3;
    grid-template-rows: 1fr;
  }
}

.grid-search-item {
  border: 1px solid;
}

@media only screen and (min-width: 768px) {
  .grid-search {
    grid-template-columns: repeat(8, 1fr);
  }

  .grid-search-item {
    grid-column: span 4;
    grid-template-rows: 1fr;
  }

  .grid-search-item:nth-child(1) {
    grid-column: span 8;
    grid-row: span 2;
  }
}

@media only screen and (min-width: 992px) {
  .grid-search {
    grid-template-columns: repeat(12, 1fr);
  }

  .grid-search-item {
    grid-column: span 3;
    grid-template-rows: 1fr;
  }

  .grid-search-item:nth-child(1) {
    grid-column: span 9;
    grid-row: span 2;
  }
}

.headline h2:before {
  content: "";
  width: 3vw;
  height: 4px;
  background: var(--theme-hover);
  position: absolute;
  bottom: -12px;
  left: 0;
}

.secondary-header {
  position: relative;
}

.btn__custom {
  border: 1px solid;
  padding: 10px;
  font-weight: bolder;
  display: block;
  border: 1px solid var(--theme-hover);
  color: var(--theme-hover);
}

.btn__custom:hover {
  background: var(--theme-hover);
  color: white;
}

.place-names {
  margin-bottom: 20px;
  margin-top: 0;
  color: var(--theme);
  font-family: Manuale, san-serif;
  font-weight: normal;
}

.search-btn {
  background: var(--theme-hover);
}

.td-title {
  width: 20%;
}
.badge-info-custom {
  border: 1px solid #e7e7e7;
  padding: 5px;
  color: #aaa;
}
.table,
.table a {
  /* color: var(--body-font-color); */
}
.pagination .page-item.active .page-link {
  color: white;
  background: var(--theme-hover);
  border: none;
}
.pagination .page-link {
  color: var(--theme);
}
iframe {
  border: none;
}
.autocomplete-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #899297;
  opacity: 1; /* Firefox */
}

.autocomplete-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #899297;
}

.autocomplete-input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #899297;
}
.link-background {
  position: relative;
  display: block;
}
.link-background::after {
  content: "";
  display: block;
  background-image: url(https://uptodatewebdesign.s3.eu-west-3.amazonaws.com/cdn/client-assets/images/bg.png);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 2;
  border: 1px solid #ccc;
  background-color: white;
  padding: 10px;
}
</style>